import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { FormattedMessage } from 'react-intl';

const SelectLanguage = ({ langs, css }) => {

    const notCurrentLanguage = langs.find(lang => !lang.selected)

    return (
        <Link
            to={notCurrentLanguage.link}
            key={notCurrentLanguage.langKey}
            className={css}
            activeClassName="text-red-600"
        >
            <FormattedMessage id="selectLanguage" />
        </Link>
    )
};

SelectLanguage.propTypes = {
    langs: PropTypes.array
};

export default SelectLanguage;