import PropTypes from "prop-types"
import React from "react"
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { IntlProvider } from 'react-intl';
import "../styles/style.css"
import Footer from "../components/Footer"
import Header from "../components/Header"


const Layout = ({ data, location, children }) => {
  const url = location.pathname;
  const { langs, defaultLangKey } = data.siteMetadata.languages;
  let langKey = getCurrentLangKey(langs, defaultLangKey, url);
  const homeLink = `/${langKey}/`;
  let langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url));
  const i18nMessages = require(`../data/messages/${langKey}`);

  return (
    <IntlProvider
      locale={langKey}
      messages={i18nMessages}
    >
      <Header langs={langsMenu} />
      {children}
      <Footer />
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
}

export default Layout
