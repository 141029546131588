import { Link } from "gatsby"
import { motion } from "framer-motion"
import { FormattedMessage } from 'react-intl'
import PropTypes from "prop-types"
import React from "react"
import Overlay from "./Overlay"
import SelectLanguage from "./SelectLanguage"


const menuItem = {
  closed: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
    x: -20,
  },
  open: key => ({
    opacity: 1,
    transition: {
      delay: 0.25 + key * 0.1,
      type: "tween",
    },
    x: 0,
  }),
}

const buttonStyle = "my-6 font-sans bg-white font-bold text-gray-600  hover:text-gray-400 last:bg-red-600 last:text-white rounded-2xl px-4 py-2 hover:bg-opacity-75"


const MenuMobile = ({ links, isOpen, setIsOpen, langs }) => {
  const currentLanguage = langs.find(lang => lang.selected)

  return (
    <Overlay isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="container flex flex-col justify-center text-ckdarkgray bg-ckyellow">
        <ul className="text-center">
          <motion.li
            className={buttonStyle}
            animate={isOpen ? "open" : "closed"}
            key={`menu_mobile_link_language`}
            variants={menuItem}
          >
          <SelectLanguage langs={langs} css="text-2xl"/>
          </motion.li>
          {links.map((link, key) => (
            <motion.li
              className={buttonStyle}
              animate={isOpen ? "open" : "closed"}
              custom={key}
              key={`menu_mobile_link${key}`}
              variants={menuItem}
            >
              <Link
                className="text-2xl"
                activeClassName="font-semibold"
                to={link.to.includes('https') ? `${link.to}`: `/${currentLanguage.langKey}${link.to}`}
                onClick={() => setIsOpen(false)}
              >
                <FormattedMessage id={link.id} />
              </Link>
            </motion.li>
          ))}
        </ul>
      </div>
    </Overlay>
  )
}

MenuMobile.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default MenuMobile
