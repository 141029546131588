import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import MenuMobile from "./MenuMobile"
import { FaBars } from "react-icons/fa"
import SelectLanguage from "./SelectLanguage"
import { FormattedMessage } from "react-intl"
import Dropdown from "./Dropdown"

const buttonStyle = "font-sans bg-white text-gray-600 ml-6 sm:ml-8 text-sm sm:text-base font-bold border-b-2 px-4 py-2 rounded-2xl border-transparent hover:text-gray-400 transition duration-150 ease-in-out last:text-white last:bg-red-600 last:rounded-2xl last:px-4 last:py-2 hover:bg-opacity-75"

const Header = ({ langs }) => {

  const currentLanguage = langs.find(lang => lang.selected)

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [navBackground, setNavBackground] = useState('appBarTransparent')

  const useStyles = {
    appBarTransparent: "transition duration-500 ease-in border-black",
    appBarSolid: "bg-ckyellow border-opacity-100 md:bg-opacity-100 transition duration-500 ease-in",
  }
  const navRef = useRef()
  navRef.current = navBackground

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 200
      if (show) {
        setNavBackground('appBarSolid')

      } else {
        setNavBackground('appBarTransparent')

      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
            id
          }
        }
      }
    }
  `)

  return (
    /* for future use <div className="container pt-6 pb-12 md:pt-12 fixed z-10">*/
    <div className={`w-screen fixed z-50 ${useStyles[navRef.current]}`}>

      <div className="flex justify-between items-center max-w-4/5 mx-auto">
        <Link to={`/${currentLanguage.langKey}`}>
          <img alt="Logo" className="h-20 w-32" src="/logo.svg" />
        </Link>

        <button
          className="block md:hidden"
          onClick={() => setIsMenuOpen(true)}
          aria-label="Open Menu"
        >
          <FaBars className="h-6 w-auto fill-current -mt-1" />
        </button>

        <div className="hidden md:block">
          <div className="flex flex-row">

            <div className="flex flex-row">
              {site.data.menu.map((link, key) => (
                <Link
                  key={`menu_desktop_link${key}`}
                  className={buttonStyle}
                  activeClassName="text-red-600"
                  to={link.to.includes('https') ? `${link.to}` : `/${currentLanguage.langKey}${link.to}`}
                >
                  <FormattedMessage id={link.id} />
                </Link>
              ))}
            </div>
            <Dropdown langs={langs} css={buttonStyle} />
          </div>
        </div>
      </div>
      <MenuMobile
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        links={site.data.menu}
        langs={langs}
      />
    </div >
  )
}

export default Header
