/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Link from 'gatsby-link';
import { FormattedMessage } from 'react-intl';
import { IoMdArrowDropdown } from 'react-icons/io'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Dropdown({ langs }) {

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="font-sans bg-white text-gray-600 ml-6 sm:ml-8 text-sm sm:text-base font-bold border-b-2 px-4 py-2 rounded-2xl border-transparent hover:text-gray-400 transition duration-150 ease-in-out">
                    <FormattedMessage id="selectLanguage" />
                    <IoMdArrowDropdown className="inline-block" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {
                            langs && langs.map((lang) => (
                                <Menu.Item>
                                    {({ active }) => (
                                        <Link
                                            to={lang.link}
                                            key={langs.langKey}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            )}
                                        >
                                            {
                                                lang.langKey === 'en-US' ? <FormattedMessage id="englishLanguage" />
                                                    : lang.langKey === 'ja-JP' ? <FormattedMessage id="japaneseLanguage" />
                                                        : lang.langKey === 'zh-HK' ? <FormattedMessage id="chineseLanguage" />
                                                            : null
                                            }
                                        </Link>
                                    )}
                                </Menu.Item>
                            ))
                        }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
