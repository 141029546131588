module.exports = {
  'selectLanguage': '日本語',
  'englishLanguage': 'English',
  'japaneseLanguage': '日本語',
  'chineseLanguage': '中文 (繁體)',
  'signup.placeholder': 'Eメールを入力してください',
  'header.button.join': 'SNS',
  'header.button.blog': 'ブログ',
  'header.button.preorder': '予約注文',
  'header.button.buy': 'すぐ買う',
  'header.button.download': 'ダウンロード',
  'footer.privacy': 'プライバシーポリシー',
  'footer.allrights': 'All rights reserved',
  'signup.title': '今すぐニュースレターに登録',
  'signup.subtitle': '登録者限定の特典をお知らせします',
  'signup.description': "ここに入力されたメールアドレスは、ChonkerKeysからの連絡のためにのみ使用します。共有することはありません。当社のプライバシーポリシーをご覧ください。お客様は、当社のマーケティングメールの下部にある購読中止のリンクをクリックする、または までメールでご連絡いただければ、いつでも同意を撤回することができます",
  'signup.cta.title': "通知を受ける",
  'press.title': 'チームと支援者たちのおかげで：',
  'press.list.title': '20以上のメディアで紹介されました!',
  'hover.mic.title': 'マイク On/Off',
  'hover.mic.description': 'このキーでマイクのミュート、ミュート解除を調節できます。LEDライトがマイクのオンオフを表します。マイクがオンになっていた恥ずかしさとはおさらば!',
  'hover.camera.title': 'カメラ On/Off',
  'hover.camera.description': 'ボタンでカメラをON/OFFできます。LEDライトはカメラのオンオフを示し、他の人からあなたが見えるのか教えてくれます。カメラがオンになっていた恥ずかしい瞬間とはおさらば!',
  'hover.leave.title': '会議退出',
  'hover.leave.description': "最高のボタンだと思いますので、特大サイズにふさわしい! 大きな赤いボタンで、ビデオ会議をすぐに出て、他のことに向かうことができますね。デフォルトでは、退出時に確認が求められるようになっていますので、ご安心下さい!",
  'hover.raisehand.title': '手を挙げる',
  'hover.raisehand.description': 'キークリックで挙手します。自分の手を上げるのがめんどくさい？ならこのボタンが最適!',
  'hover.sharescreen.title': '画面共有',
  'hover.sharescreen.description': '画面共有を開始することも終了することも可能です。もうマウスやキーボードをいじらなくても大丈夫!',
  'hover.vol.title': '音量調節',
  'hover.vol.description': 'クリックだけでデバイスの音量を調節します。音量ミュートボタンも近日公開予定です。',
  'hover.play.title': '再生・一時停止',
  'hover.play.description': 'キークリックするだけで、すぐにメディアを再生・一時停止できます。',
  'hover.skip.title': '次を再生',
  'hover.skip.description': 'キークリックするだけで、すぐに次のメディアへスキップしたり再生することができます。',
  'hover.comment.title': 'コメント',
  'hover.comment.description': 'The Maxのおまけキー。何か伝えたい?このキーをクリックするだけでメッセージウィンドウを開いてメッセージを送ることができます。',
  'hover.like.title': 'いいね!',
  'hover.like.description': 'The Maxのおまけキー。何か気に入った? キークリックですぐに「いいね!」をしましょう!',
  'hover.record.title': '画面録画',
  'hover.record.description': 'The Maxのおまけキー。キークリックですぐに画面録画できます。',
  'hover.usb.title': 'USB ケーブル',
  'hover.usb.description': 'ChonkerKeysのご注文は、レトロのようなコイルUSB-Cケーブルが付きます。',
  'signup.mailchimp.key': 'https://kibidango.us1.list-manage.com/subscribe/post?u=e164fc840c4784f0811793041&amp;id=4336d927d1',
  'signup.mailchimp.tags': '4152214,4152218',
  'download.title': 'ChonkerKeys ソフトウェア ダウンロード',
  'download.description': 'このソフトはパソコンで対応アプリの管理、キー機能を更新やChonkerKeys再構成できるアプリケーションです。',
  'download.windows.compatibility': 'Windows 10, 11で動作',
  'download.windows.version': '1.1.5',
  'download.mac.compatibility': 'macOS 10.13+で動作',
  'download.mac.version': '1.1.5',
  "footer.overview.title": "概要",
  "footer.legal.title": "法的事項",
  "footer.follow.title": "SNSでフォロー",
  "footer.help.title": "お問い合わせ",
  "footer.help.contact": "右下にあるウィジェットで連絡して下さい",
  "footer.help.email": "メールでのお問い合わせはこちら",
  "footer.overview.home": "ホーム",
  "footer.overview.compatibility": "互換性",
  "footer.overview.download": "ダウンロード",
  "footer.overview.faqs": "よくあるご質問",
  "footer.legal.privacy": "個人情報保護方針",
  "footer.legal.tos": "利用規約",
  "landing.hero.holidaytag": "2023年おめでとう! ChonkerKeysバンドルはアメリカ内への発送は無料。全品4%オフ。今すぐ買う！",
};
