module.exports = {
  'selectLanguage': '中文 (繁體)',
  'englishLanguage': 'English',
  'japaneseLanguage': '日本語',
  'chineseLanguage': '中文 (繁體)',
  'signup.placeholder': '請輸入您的郵箱地址',
  'header.button.join': '加入我們的談話',
  'header.button.blog': '博客',
  'header.button.preorder': '預售',
  'header.button.buy': '立即購買',
  'header.button.download': '下載',
  'footer.privacy': '私隱政策',
  'footer.allrights': 'All Rights Reserved',
  'signup.title': '現在報名參與預售',
  'signup.subtitle': '獲得預售通知和訂閱者的獨家優惠!',
  'signup.description': "我們只將您在這裡輸入的電子郵件地址用於ChonkerKeys通信。我們不會分享它。請參閱我們的私隱政策。您可以在任何時候通過點擊我們任何營銷郵件底部的退訂鏈接撤回您的同意，或者給我們發郵件",
  'signup.cta.title': "獲取通知",
  'press.title': '謝謝支持我們的支持者和我們的團隊，我們:',
  'press.list.title': '20+多家和更多媒體所推薦!',
  'hover.mic.title': '靜音/解除靜音',
  'hover.mic.description': '按下將您的麥克風設為「靜音」或「解除靜音」。透過鍵盤背光顯示您的麥克風收音狀態，避免加入不必要的聲效！',
  'hover.camera.title': '開始 / 停止視訊',
  'hover.camera.description': '按下即開啟或關閉攝影機。透過鍵盤背光顯示您的攝影機開關狀態，避免任何尷尬場面！',
  'hover.leave.title': '離開會議',
  'hover.leave.description': "按下紅色鍵即可執行「離開會議」，讓您迅速離開繼續下一個工作。請放心，我們一般會要求進行最後確認才讓您真正離開會議。",
  'hover.raisehand.title': '舉手',
  'hover.raisehand.description': '按一下讓您在會議中輕鬆舉手回應。平時懶的舉手？這正是為您而設的！',
  'hover.sharescreen.title': '分享畫面',
  'hover.sharescreen.description': '按下以打開「分享畫面」選項或直接「停止分享」畫面，輕鬆跳過主鍵盤及滑鼠操作。',
  'hover.vol.title': '音量調節',
  'hover.vol.description': "按下以調高或調低揚聲器或耳機的音量。",
  'hover.play.title': '播放/暫停',
  'hover.play.description': '按下以播放或暫停播放歌曲。',
  'hover.skip.title': '播放/跳過',
  'hover.skip.description': '按下以跳過或播放下一首歌曲。',
  'hover.comment.title': '聊天',
  'hover.comment.description': 'The Max 隨附的獎勵按鍵。 想說點什麼嗎？按一下就能打開聊天面板開始對話。',
  'hover.like.title': '按讚',
  'hover.like.description': 'The Max 隨附的獎勵按鍵。 覺得有意思嗎？按一下立刻給讚。',
  'hover.record.title': '錄製',
  'hover.record.description': 'The Max 隨附的獎勵按鍵。按下即開啟「錄製」視訊畫面。',
  'hover.usb.title': 'USB傳輸線',
  'hover.usb.description': '每份創客鍵套裝裡都配有一個複古線圈USB-A到USB-C傳輸線。',
  'signup.mailchimp.key': 'https://chonkerkeys.us7.list-manage.com/subscribe/post?u=ac16f42c5affbb6b6658ad19d&amp;id=b92ebf5edb',
  'signup.mailchimp.tags': '6694800',
  'download.title': '下載創客鍵軟件',
  'download.description': '創客鍵軟件可以讓您管理我們支援的應用程式，更新功能鍵和自組您專屬的創客鍵。',
  'download.windows.compatibility': '支援 Windows 10, and 11',
  'download.windows.version': '1.1.5',
  'download.mac.compatibility': '支援 macOS 10.13+',
  'download.mac.version': '1.1.5',
  "footer.overview.title": "我們的產品",
  "footer.legal.title": "法務",
  "footer.follow.title": "關注我們",
  "footer.help.title": "需要支援? 聯繫我們",
  "footer.help.contact": "聯繫我們的客服支援（右下方）",
  "footer.help.email": "電郵給我們",
  "footer.overview.home": "首頁",
  "footer.overview.compatibility": "軟件兼容性",
  "footer.overview.download": "下載",
  "footer.overview.faqs": "常見問題",
  "footer.legal.privacy": "私隱政策",
  "footer.legal.tos": "條款及細則",
  "landing.hero.holidaytag": "🎊 2023新年快樂! 創客鍵組合套裝可享免運費到美國,另外其他產品可享96折優惠",
};
