module.exports = {
  'selectLanguage': 'English',
  'englishLanguage': 'English',
  'japaneseLanguage': '日本語',
  'chineseLanguage': '中文 (繁體)',
  'signup.placeholder': 'Enter your email address',
  'header.button.join': 'Join our conversation',
  'header.button.blog': 'Blog',
  'header.button.buy': 'Buy now',
  'header.button.download': 'Download',
  'header.button.preorder': 'Pre-order',
  'footer.privacy': 'Privacy Policy',
  'footer.allrights': 'All Rights Reserved',
  'signup.title': 'Sign up for our newsletter',
  'signup.subtitle': 'Get notified of exclusive subscriber-only perks!',
  'signup.description': "We only use your email address entered here for ChonkerKeys communications only. We don't share it. See our Privacy Policy. You may withdraw your consent at any time by clicking the unsubscribe link at the bottom of any of our marketing emails, or by emailing us at ",
  'signup.cta.title': "GET NOTIFIED",
  'press.title': 'Thanks to our backers and our team, we:',
  'press.list.title': 'Featured by 20+ presses and more!',
  'hover.mic.title': 'Microphone On/Off',
  'hover.mic.description': 'Button to mute or unmute your microphone with a button tap. LED light indicates the status of your microphone. No more hot mic mistakes!',
  'hover.camera.title': 'Camera On/Off',
  'hover.camera.description': 'Button to turn your camera on or off. LED light indicate the status of your camera and if others can see you. No more embarrassing moments!',
  'hover.leave.title': 'Leave Meeting',
  'hover.leave.description': "We think this is the best button, that's why it deserves the bigger size!Big red button for you to leave a video conference instantly so you can move on to do other things. Don't worry, we make it so that by default you'll still be asked for confirmation upon leaving.",
  'hover.raisehand.title': 'Raise Hand',
  'hover.raisehand.description': 'Virtually raise your hand with a button tap. Cannot be bothered to physically raise yours? Then this button is for you!',
  'hover.sharescreen.title': 'Share Screen',
  'hover.sharescreen.description': 'Initiate a screen sharing session, or turn it off instantly. No more fiddling with mouse and keyboard!',
  'hover.vol.title': 'Volume Control',
  'hover.vol.description': "Instantly adjust your device's volume with a finger tap. There will also be a mute button soon!",
  'hover.play.title': 'Play / Pause',
  'hover.play.description': 'Instantly play or pause your media with a tap of the button!',
  'hover.skip.title': 'Play / Skip',
  'hover.skip.description': 'Instantly skip or play your next media with a tap of the button!',
  'hover.comment.title': 'Comment',
  'hover.comment.description': 'Bonus key for The Max. Want to say something? Now you can do that with a button tap to open the message window instantly!',
  'hover.like.title': 'Like',
  'hover.like.description': 'Bonus key for The Max. See something you like? Hit the button to give that Like instantly!',
  'hover.record.title': 'Record',
  'hover.record.description': 'Bonus key for The Max. Instantly record your screen with a button tap!',
  'hover.usb.title': 'USB Cable',
  'hover.usb.description': 'Each ChonkerKeys order comes with a retro-inspired future-proof coil USB-A to USB-C cable.',
  'signup.mailchimp.key': 'https://chonkerkeys.us7.list-manage.com/subscribe/post?u=ac16f42c5affbb6b6658ad19d&amp;id=b92ebf5edb',
  'signup.mailchimp.tags': '6694800',
  'download.title': 'Download ChonkerKeys',
  'download.description': 'This application allows you to manage supporting apps, update key functions, as well as reconfiguration of your ChonkerKeys.',
  'download.windows.compatibility': 'Works with Windows 10, and 11',
  'download.windows.version': '1.1.5',
  'download.mac.compatibility': 'Works with macOS 10.13+',
  'download.mac.version': '1.1.5',
  "footer.overview.title": "Overview",
  "footer.legal.title": "Legal",
  "footer.follow.title": "Follow Us",
  "footer.help.title": "Need Help? Contact Us",
  "footer.help.contact": "Contact us at the widget (Bottom Right)",
  "footer.help.email": "Email us at",
  "footer.overview.home": "Home",
  "footer.overview.compatibility": "Compatibility",
  "footer.overview.download": "Download",
  "footer.overview.faqs": "FAQs",
  "footer.legal.privacy": "Privacy Policy",
  "footer.legal.tos": "Terms of Service",
  "landing.hero.holidaytag": "ChonkerKeys Bundle ships free to the U.S. and 4% off for everything. Checkout now!",
};
