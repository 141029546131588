import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { FaInstagram, FaFacebookSquare, FaTwitterSquare, FaYoutubeSquare, FaPinterest, FaDiscord } from "react-icons/fa"
import { FormattedMessage, injectIntl } from 'react-intl';

const Footer = ({ intl }) => {
  const translate = messageId => intl.formatMessage({ id: messageId })

  const {
    site: {
      meta: { links, footer },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        meta: siteMetadata {
          links {
            instagram
            facebook
            twitter
            youtube
            pinterest
            discord
          }
          footer {
            overview {
              id
              name
              to
            }
            legal {
              id
              name
              to
            }
          }
        }
      }
    }
  `)

  return (
    <div className="bg-cklightgray">
      <div className="container h-full py-16">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex mb-16">
            <FooterBlock heading={translate("footer.overview.title")} items={footer.overview} intl={intl} />
            <FooterBlock heading={translate("footer.legal.title")} items={footer.legal} intl={intl} />
          </div>
          <div className="text-left lg:text-right">
            <H1>{translate("footer.help.title")}</H1>
            <p className="!leading-8 small">{translate("footer.help.contact")}</p>
            <p className="!leading-8 small">{translate("footer.help.email")} <a className="underline" href='mailto:support@chonkerkeys.com'>support@chonkerkeys.com</a>
            </p>
          </div>
        </div>
        <div>
          <div className="flex  flex-col md:flex-row justify-between">
            <div className="flex">
              <H1>{translate("footer.follow.title")}</H1>
              <ul className="flex items-center md:order-2">
                <FooterLink
                  href={links.facebook}
                  icon={FaFacebookSquare}
                  label="Facebook"
                />
                <FooterLink
                  href={links.instagram}
                  icon={FaInstagram}
                  label="Instagram"
                />
                <FooterLink
                  href={links.twitter}
                  icon={FaTwitterSquare}
                  label="Twitter"
                />
                <FooterLink
                  href={links.youtube}
                  icon={FaYoutubeSquare}
                  label="Youtube"
                />
                <FooterLink
                  href={links.pinterest}
                  icon={FaPinterest}
                  label="Pinterest"
                />
                <FooterLink
                  href={links.discord}
                  icon={FaDiscord}
                  label="Discord"
                />
              </ul>
            </div>
            <div className="my-6 md:order-1 lg:flex lg:flex-col md:justify-end lg:items-end">
              <p className="text-left lg:text-center text-sm md:text-base">
                &copy; {new Date().getFullYear()} ANWC Limited. <FormattedMessage id="footer.allrights" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const H1 = ({ children }) => <h1 className="my-6">{children}</h1>

const FooterBlock = ({ heading, items, intl }) => {
  const translate = messageId => intl.formatMessage({ id: messageId })
  return (
    <div className="mr-20">
      <H1>{heading}</H1>
      <ul className="leading-8">
        {
          items && items.map(item =>
            <Link
              key={item.name}
              to={item.to.includes('https') || item.to.includes('privacy') ? `${item.to}` : `/${intl.locale}${item.to}`}
            >
              <li>{translate(item.id)}</li>
            </Link>)
        }
      </ul>
    </div>
  )
}

const FooterLink = ({ href, label, icon: Icon }) => {
  return (
    <li className="inline-block pl-6">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="text-gray-500 hover:text-ckred transition duration-150 ease-in-out"
      >
        <span className="sr-only">{label}</span>
        <Icon className="w-5 h-5 md:w-8 md:h-8 fill-current" />
      </a>
    </li>
  )
}

export default injectIntl(Footer)
